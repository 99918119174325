// default animation trigger
const animatelements = document.querySelectorAll('.js-animate-trigger');
const anchorlinks = document.querySelectorAll('.js-anchor-link');

const DEFAULTMARGINBOTTOM = 30;
const DEFAULTMARGINTOP = 30;
const DEFAULTTHRESHOLD = 0.08;

const DEFAULTMARGINBOTTOMANCHOR = 0;
const DEFAULTMARGINTOPANCHOR = 0;

const ACTIVE_CLASS = 'in-view';
const ACTIVATED_CLASS = 'animation-done';
const DELAY = 0.1;

function toggleAnimationClasses(entries, observer) {
  entries.forEach((entry) => {
    entry.target.classList.toggle(ACTIVE_CLASS, entry.isIntersecting);
    // //if (!entry.target.classList.contains('js-build-in-trigger')) continue; wy?????
    if (entry.isIntersecting) {
      entry.target.querySelectorAll('.js-animate-item').forEach((item, i) => {
        item.style.setProperty('--animation-delay', `${i * DELAY}s`);
        item.classList.add(ACTIVATED_CLASS);
        // item.classList.toggle(BUILDINCLASS, entry.isIntersecting);
        observer.unobserve(entry.target);
      });
      entry.target.querySelectorAll('.js-animate-title').forEach((item, i) => {
        item.classList.add(ACTIVATED_CLASS);
        // item.classList.toggle(BUILDINCLASS, entry.isIntersecting);
        observer.unobserve(entry.target);
      });
    }
  });
}

function toggleAnchorlinkClasses(entries, observer) {
  entries.forEach((entry) => {
    const link = document.querySelector(`[href="#${entry.target.getAttribute('id')}"]`);
    link.classList.toggle(ACTIVE_CLASS, entry.isIntersecting);
  });
}

window.lazyBackgroundObserver = new IntersectionObserver(toggleAnimationClasses, {
  rootMargin: `-${DEFAULTMARGINTOP}% 0% -${DEFAULTMARGINBOTTOM}% 0%`,
  threshold: DEFAULTTHRESHOLD,
});

const lazyBackgroundObserverAnchorlink = new IntersectionObserver(toggleAnchorlinkClasses, {
  rootMargin: `-${DEFAULTMARGINTOPANCHOR}% 0% -${DEFAULTMARGINBOTTOMANCHOR}% 0%`,
  threshold: DEFAULTTHRESHOLD,
});

// add observers
animatelements.forEach((element, index) => {
  lazyBackgroundObserver.observe(element);
});

anchorlinks.forEach((element, index) => {

  const targetid = element.getAttribute('href');
  const targetElement = document.querySelector(targetid);
  if (targetElement) {
    lazyBackgroundObserverAnchorlink.observe(targetElement);
  }
});
